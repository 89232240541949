class ResponsiveTextModifier {
    constructor() {
        this.heading = document.getElementById('promo-block--heading');
        if (!this.heading) return;
        this.originalText = this.heading.textContent;
        this.tabletBreakpoint = 1199;
        this.mobileBreakpoint = 649;

        // Bind the modifyText method to the instance
        this.modifyText = this.modifyText.bind(this);
    }

    modifyText() {
        if (!this.heading) return;
        const windowWidth = window.innerWidth;

        if (windowWidth <= this.mobileBreakpoint) {
            // For mobile screens, show only the first word
            this.heading.textContent = this.originalText.split(' ')[0];
        } else if (windowWidth <= this.tabletBreakpoint) {
            // For tablet screens, show the first and second words
            this.heading.textContent = this.originalText.split(' ').slice(0, 2).join(' ');
        } else {
            // For desktop screens, show the original text
            this.heading.textContent = this.originalText;
        }
    }

    ready() {
        // Add event listeners for load and resize events
        window.addEventListener('load', this.modifyText);
        window.addEventListener('resize', this.modifyText);
    }
}

export default new ResponsiveTextModifier();