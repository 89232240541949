const cookiesLanguageSettings = {
  en: {
    consent_modal: {
      title: " We use cookies! ",
      description:
        'Yuzu uses essential cookies to ensure its proper operation, especially for mobile view preferences, and tracking cookies from GA4, Hotjar, and Bing Analytics to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
      primary_btn: {
        text: "Accept all",
        role: "accept_all",
      },
      secondary_btn: {
        text: "Reject all",
        role: "accept_necessary",
      },
    },
    settings_modal: {
      title: "Cookies settings",
      save_settings_btn: "Save settings",
      accept_all_btn: "Accept all",
      reject_all_btn: "Reject all",
      close_btn_label: "Close",
      cookie_table_headers: [
        { col1: "Name" },
        { col2: "Domain" },
        { col3: "Expiration" },
        { col4: "Description" },
      ],
      blocks: [
        {
          title: "Cookie usage",
          description:
            "Yuzu uses cookies to ensure basic functionalities of the website, particularly for mobile view preferences, and to enhance your online experience. You can choose to accept or decline each category anytime. For more details about cookies and other sensitive data.",
        },
        {
          title: "Strictly necessary cookies",
          description:
            "These cookies are essential for the proper functioning of the Yuzu website, especially for mobile view preferences. Without these cookies, the website wouldn't function properly",
          toggle: {
            value: "necessary",
            enabled: true,
            readonly: true,
          },
        },
        {
          title: "Performance and Analytics cookies",
          description:
            "These cookies, sourced from GA4, Hotjar, and Bing Analytics, allow Yuzu to understand user interactions, improve the user experience, and analyze traffic.",
          toggle: {
            value: "analytics",
            enabled: false,
            readonly: false,
          },
          cookie_table: [
            {
              col1: "_ga",
              col2: "google.com",
              col3: "2 years",
              col4: "Used by Google Analytics (GA4) to distinguish users.",
              is_regex: true,
            },
            {
              col1: "_hjid",
              col2: "yuzusushi.ca",
              col3: "1 year",
              col4: "Used by Hotjar to track user interactions.",
            },
            {
              col1: "_uetsid",
              col2: "bing.com",
              col3: "30 minutes",
              col4: "Used by Bing Analytics to track user sessions.",
            },
          ],
        },
        {
          title: "More information",
          description:
            'For any inquiries regarding Yuzu\'s cookie policy and your choices, please <a class="cc-link" target="_blank" href="https://mtygroup.com/en/cookie-policy-en/">contact us</a>.',
        },
      ],
    },
  },
  fr: {
    consent_modal: {
      title: "Nous utilisons des cookies ! ",
      description:
        'Yuzu utilise des cookies essentiels pour garantir son bon fonctionnement, en particulier pour les préférences de vue mobile, ainsi que des cookies de suivi de GA4, Hotjar et Bing Analytics pour comprendre comment vous interagissez avec lui. Ces derniers ne seront installés qu\'après votre consentement. <button type="button" data-cc="c-settings" class="cc-link">Laissez-moi choisir</button>',
      primary_btn: {
        text: "Accepter tout",
        role: "accept_all",
      },
      secondary_btn: {
        text: "Refuser tout",
        role: "accept_necessary",
      },
    },
    settings_modal: {
      title: "Paramètres des cookies",
      save_settings_btn: "Enregistrer les paramètres",
      accept_all_btn: "Accepter tout",
      reject_all_btn: "Refuser tout",
      close_btn_label: "Fermer",
      cookie_table_headers: [
        { col1: "Nom" },
        { col2: "Domaine" },
        { col3: "Expiration" },
        { col4: "Description" },
      ],
      blocks: [
        {
          title: "Utilisation des cookies ",
          description:
            "Yuzu utilise des cookies pour garantir les fonctionnalités de base du site, en particulier pour les préférences de vue mobile, et pour améliorer votre expérience en ligne. Vous pouvez choisir d'accepter ou de refuser chaque catégorie à tout moment.",
        },
        {
          title: "Cookies strictement nécessaires",
          description:
            "Ces cookies sont essentiels au bon fonctionnement du site Yuzu, notamment pour les préférences de vue mobile. Sans ces cookies, le site ne fonctionnerait pas correctement",
          toggle: {
            value: "necessary",
            enabled: true,
            readonly: true,
          },
        },
        {
          title: "Cookies de performance et d'analyse",
          description:
            "Ces cookies, provenant de GA4, Hotjar et Bing Analytics, permettent à Yuzu de comprendre comment les utilisateurs interagissent avec le site, d'améliorer l'expérience utilisateur et d'analyser le trafic.",
          toggle: {
            value: "analytics",
            enabled: false,
            readonly: false,
          },
          cookie_table: [
            {
              col1: "_ga",
              col2: "google.com",
              col3: "2 ans",
              col4: "Utilisé par Google Analytics (GA4) pour distinguer les utilisateurs.",
              is_regex: true,
            },
            {
              col1: "_hjid",
              col2: "yuzusushi.ca",
              col3: "1 an",
              col4: "Utilisé par Hotjar pour suivre l'interaction des utilisateurs.",
            },
            {
              col1: "_uetsid",
              col2: "bing.com",
              col3: "30 minutes",
              col4: "Utilisé par Bing Analytics pour suivre les sessions des utilisateurs.",
            },
          ],
        },
        {
          title: "Plus d'informations",
          description:
            'Pour toute question concernant la politique sur les cookies de Yuzu et vos choix, veuillez <a target="_blank" class="cc-link" href="https://mtygroup.com/cookie-policy-ca/">nous contacter</a>.',
        }, // Find way to get contact link dynamically
      ],
    },
  },
};

export default cookiesLanguageSettings;
