/**
 * @class UserAgreement
 */
class UserAgreement {
    /**
     * Creates an instance of UserAgreement.
     *
     * @memberof UserAgreement
     */

    constructor() {
        /**
         * current active checkbox
         */
        this.activeCheckbox = null;
        this.activeFormSaveButtonElement = null;
        this.modal = null;
    }

    /**
     * init the class
     */
    ready() {
        const userAgreementCheckbox = $("#user-agreement");
        const userAgreementLabel = $("#userAgreementLabel");
        const userAgreementContactCheckbox = $("#userAgreementContact");
        const userAgreementContactLabel = $("#userAgreementContactLabel");
        const privacyPolicyCheckbox = $("#privacyPolicyCheckbox");
        const shareInformationCheckbox = $("#shareInformationCheckbox");
        const newsletterSubmitButton = $("#newsletterSubmitButton");
        const contactFormSaveButton = $("#contactFormSaveButton");
        const contactModal = $("#contactAgreementModal");
        const newsletterModal = $("#newsletterAgreementModal");

        /**
         * handle the click on @userAgreementCheckbox the checkbox
         */
        userAgreementCheckbox.add(userAgreementLabel).on("mousedown", (e) => {
            this.activeCheckbox = userAgreementCheckbox;
            this.activeFormSaveButtonElement = newsletterSubmitButton;
            this.modal = newsletterModal;
            this.handleCheckboxClick(e);
        });

        /**
         * handle the click on @userAgreementContactCheckbox the checkbox
         */
        userAgreementContactCheckbox
            .add(userAgreementContactLabel)
            .on("mousedown", (e) => {
                this.activeCheckbox = userAgreementContactCheckbox;
                this.activeFormSaveButtonElement = contactFormSaveButton;
                this.modal = contactModal;
                this.handleCheckboxClick(e);
            });

        /**
         * handle the click on @shareInformationCheckbox checkboxs
         * and save the modal
         *
         * @param {*} e
         * @memberof UserAgreement
         * @returns {void}
         */
        $("#contactUserAgreementModalSave").on("click", () => {
            this.handleContactModalSave(shareInformationCheckbox);
        });

        /**
         * handle the click on @privacyPolicyCheckbox checkboxs
         * and save the modal
         *
         * @param {*} e
         * @memberof UserAgreement
         * @returns {void}
         */
        $("#newsLetteruserAgreementModalSave").on("click", () => {
            this.handleNewsletterModalSave(privacyPolicyCheckbox);
        });
    }

    /**
     * handle the click on the checkbox
     *
     * @param {*} e
     * @memberof UserAgreement
     */
    handleCheckboxClick(e) {
        e.preventDefault();
        !this.activeCheckbox.is(":checked")
            ? this.modal.modal("show")
            : this.activeFormSaveButtonElement.attr("disabled", true);
    }

    /**
     * handle the save action of the modal
     *
     * If @privacyPolicyCheckbox are checked then check the @activeCheckbox
     * and remove the disabled attribute from the @activeFormSaveButtonElement
     * else uncheck the @activeCheckbox and add the disabled attribute to the @activeFormSaveButtonElement
     *
     * @param {*} privacyPolicyCheckbox
     * @memberof UserAgreement
     */
    handleNewsletterModalSave(privacyPolicyCheckbox) {
        if (privacyPolicyCheckbox.is(":checked")) {
            this.activeCheckbox.prop("checked", true);
            this.activeFormSaveButtonElement.removeAttr("disabled");
        } else {
            this.activeFormSaveButtonElement.attr("disabled", true);
            this.activeCheckbox.prop("checked", false);
        }
        this.modal.modal("hide");
        // Simply we want to rest checkboxes
        privacyPolicyCheckbox.prop("checked", false);
    }

    /**
     * handle the save action of the modal
     * if @shareInformationCheckbox is checked then check the @activeCheckbox
     * and remove the disabled attribute from the @activeFormSaveButtonElement
     * else uncheck the @activeCheckbox and add the disabled attribute to the @activeFormSaveButtonElement
     *
     *
     * @param {*} shareInformationCheckbox
     */
    handleContactModalSave(shareInformationCheckbox) {
        if (shareInformationCheckbox.is(":checked")) {
            this.activeCheckbox.prop("checked", true);
            this.activeFormSaveButtonElement.removeAttr("disabled");
        } else {
            this.activeFormSaveButtonElement.attr("disabled", true);
            this.activeCheckbox.prop("checked", false);
        }
        this.modal.modal("hide");
        // Simply we want to rest checkboxes
        shareInformationCheckbox.prop("checked", false);
    }
}

export default new UserAgreement();
