class LogoScroll {

    ready() {
        // Your existing scroll code...
        const maxScroll = $(document).height() - $(window).height();
        const maxRotation = 1000;

        $(window).on('scroll', function() {
            const st = $(this).scrollTop();
            const rotation = (st / maxScroll) * maxRotation;
            $('.hero-block--logo--text, .promo-block--logo--text').css('transform', 'rotate(' + rotation + 'deg)');
        });
    }
}

export default new LogoScroll();