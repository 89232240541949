// TOOLS
import cookie from '../tools/cookie.es6';
// FUNCTIONALITY
import carousel from '../components/carousel';
import newsletter from '../components/newsletter';
import menu from '../components/menu';
import formValidation from '../components/formValidation';
import contactForm from "../components/contacForm";
import UserAgreement from "../components/UserAgreement";
import CookieConsent from "../components/cookieConsent";
import LogoScroll from "../components/logoScroll";
import VideoControllers from "../components/videoControllers";
import ModifyText from "../components/utils/modifyText";
//import '../components/location';
//import '../components/storeApi';

$(document).ready(function(){
    /**
     * Ajax setup
     */
    let csrf = cookie.getCookie('csrftoken');
    $.ajaxSetup({ headers : {'X-CSRFToken': csrf }});
    /**
     * UI utils
     * class to put JS front-end code
     */
    carousel.ready();
    newsletter.ready();
    menu.ready();
    formValidation.ready();
    contactForm.ready();
    UserAgreement.ready();
    CookieConsent.ready();
    LogoScroll.ready();
    VideoControllers.ready();
    ModifyText.ready();
});

