import 'owl.carousel';
class carousel {
    ready() {

        $(".owl-carousel").owlCarousel({
            loop:true, 
            items:1,
            dots: true,
            nav: false,
        });


        function toggleCarousel() {

            if (window.innerWidth < 850) {
                $(".about-us__list").hide();
                $(".promotion__list").hide();
                $(".featured__list").hide();


            }
            if (window.innerWidth > 850) {
                $(".about-us__list").show();
                $(".promotion__list").show();
                $(".featured__list").show();
            }

        }

        toggleCarousel();
        window.addEventListener('resize', function () {
            toggleCarousel();
        }, true);

    }
}

export default new carousel();