class Menu {
    ready() {

        let navigationMenu = $("nav.second-nav");
        let sticky = navigationMenu.offset().top;  // Using the jQuery offset() method

        $(document).on("scroll", function () {
            (window.pageYOffset > sticky) ? navigationMenu.addClass("sticky") : navigationMenu.removeClass("sticky");
        });


        function toggleMenu() {
            if (window.innerWidth < 992) {
                $(".desktop-navigation").hide();
                $(".mobile-navigation").show();
            }
            if (window.innerWidth >= 991) {
                $(".desktop-navigation").show();
                $(".mobile-navigation").hide();

            }

        }
        toggleMenu();

        window.onresize = function () {
            toggleMenu();
        };

            // $('.navbar-toggler').on('click', function () {
            //
            //     if(!$('.navbar-collapse').hasClass('show')) {
            //         $('.open-nav').fadeOut();
            //         $('.close-nav').fadeIn();
            //     } else {
            //         $('.open-nav').fadeIn();
            //         $('.close-nav').fadeOut();
            //     }
            // });
    }
}

export default new Menu();