class VideoControllers {
    ready () {
        const Videos = (function() {
            // VARIABLES
            const $   = jQuery,   // The jquery
                players = [],       // players array (to coltrol players individually)
                queue   = [];       // videos queue (once api is ready, transform this into YT player)

            // Constructor
            function Videos() {}

            // METHODS
            // Add elements to queue
            Videos.prototype.add = function($video) {
                queue.push($video);
            };

            // Load YT API
            Videos.prototype.loadApi = function() {
                // jQuery get script
                $.getScript("//www.youtube.com/iframe_api", function() {
                    // once loaded, create the onYouTubeIframeAPIReady function
                    window.onYouTubeIframeAPIReady = function() {
                        queue.forEach(function($video) {
                            // Create the YT player
                            const player = new YT.Player($video.get(0), {
                                'width': "100%",
                                'height': "100%",
                                'videoId': $video.data("id").split('https://youtu.be/')[1],
                                playerVars: {
                                    'rel': 0,
                                    'modestbranding':   1
                                },
                                events: {
                                    'onStateChange': onPlayerStateChange
                                }
                            });
                            // add to players array
                            players.push(player);

                            if($('[data-vid*="video_id"]').length) {
                                $('[data-vid='+ $video.attr('id') +']').on('click', function () {
                                    player.playVideo();
                                    $(this).fadeOut();
                                });
                            }

                            function onPlayerStateChange(event) {
                                if (event.data == YT.PlayerState.PLAYING) {
                                    $('[data-vid='+ $video.attr('id') +']').fadeOut();
                                }
                                else if (event.data == YT.PlayerState.BUFFERING) {
                                    $('[data-vid='+ $video.attr('id') +']').fadeOut();
                                }
                                else {
                                    $('[data-vid='+ $video.attr('id') +']').fadeIn();
                                }
                            }

                        });
                    };
                });
            };

            return Videos;

        })();

        const videos = new Videos();
        $('.video').each( function () {
            videos.add( $(this) );
        });
        videos.loadApi();

        $('.video-block--video--btn').on('click', function () {
            const button = $(this);
            button.fadeOut();

            const videoContainer = $(this).next('video');
            videoContainer.trigger('play');

            videoContainer.bind('play', function() {
                button.fadeOut();
            });

            videoContainer.bind('stop, pause', function() {
                button.fadeIn();
            });
        });
    }
}

export default new VideoControllers();
