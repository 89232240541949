class formValidation {
    ready() {
        let errorMessage = document.querySelectorAll(".form__error-message")
        let errorMessageArr = Array.prototype.slice.call(errorMessage);
        $(".newsletter-form").on("submit", (e) => {
            let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

            if (!regEmail.test($("#newsletter-email").val())) {
                errorMessageArr[0].classList.add("show-error");
                e.preventDefault();
            } else {
                errorMessageArr[0].classList.remove("show-error");
            }

            if($(".template-landing-page").length === 0) {
                if ($("#first-name").val() === "") {
                    errorMessageArr[1].classList.add("show-error");
                    e.preventDefault();
                } else {
                    errorMessageArr[1].classList.remove("show-error");
                }

                if ($("#name").val() === "") {
                    errorMessageArr[2].classList.add("show-error");
                    e.preventDefault();
                } else {
                    errorMessageArr[2].classList.remove("show-error");
                }
            }
        });

        function moveLabels() {
            let labels = document.querySelectorAll('.form__input');
            let labelsArr = Array.prototype.slice.call(labels);

            labelsArr.forEach(label=>{
                if(label.value){
                    label.nextElementSibling.classList.add('label-top');
                }else{
                    label.nextElementSibling.classList.remove('label-top');
                }

            })

        }

        document.addEventListener('input',moveLabels)

    }

}

export default new formValidation;