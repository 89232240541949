import cookiesLanguageSettings from "./utils/cookiesLanguageSettings";
import "vanilla-cookieconsent/src/cookieconsent.js";
/**
 * @class CookieConsent
 */
class CookieConsent {
  /**
   * Creates an instance of CookieConsent.
   *
   * @memberof CookieConsent
   */

  constructor() {
    /**
     * Init cookie consent modal
     */
    this.cookiesConsentModal = window.initCookieConsent();
  }

  /**
   * init the class
   */
  ready() {
    this.cookiesConsentModal.run({
      current_lang: document.getElementById("currrentLanguage")?.value || "fr",
      autoclear_cookies: true,
      cookie_name: "yuzu_cookies_consent",
      cookie_expiration: 365,
      page_scripts: true,
      autorun: true,
      force_consent: true,
      gui_options: {
        consent_modal: {
          layout: "box", // box,cloud,bar
          position: "bottom right", // bottom,middle,top + left,right,center
          transition: "slide", // zoom,slide
        },
        settings_modal: {
          layout: "box", // box,bar
          // position: 'left',                // right,left (available only if bar layout selected)
          transition: "slide", // zoom,slide
        },
      },
      languages: {
        en: cookiesLanguageSettings.en,
        fr: cookiesLanguageSettings.fr,
      },
    });
  }
}

export default new CookieConsent();