// Restaurant search
class contactForm {
    ready() { 


function getStores() {
    let result =    fetch(`/stores/stores/`)
        .then(res => res.json())
        .then(data => {
            return data.stores;
    });
           
        return result;
}
       
let stores;
async function filterRestaurant(e) {

    stores = await getStores();
    $(".restaurant-list").show();
    let city = e.target.value.toUpperCase();
    const filtredRestaurant = stores.filter(store => {
        return store.city.toUpperCase().includes(city);
    });
    displayRestaurant(filtredRestaurant);

    const items = document.querySelectorAll(".store-name-list__item");
    const itemsArr = Array.prototype.slice.call(items);

    itemsArr.forEach(item => {
        item.addEventListener('click', selectRestaurent);
    });
}


function displayRestaurant(stores) {
    let filtredRestaurant = [];
    let restaurant;
    let restaurantList = document.querySelector('.restaurant-list');

    stores.forEach(s => {
        restaurant = `<span class="store-name-list__item" style="display: block;"> ${s.city} (${s.address})</span>`;
        filtredRestaurant.push(restaurant);

    });


    restaurantList.innerHTML = "";

    filtredRestaurant.forEach(store => {
        restaurantList.insertAdjacentHTML('beforeend', store);
    });

    if (document.querySelector("#restaurant").value.length == 0) {
        restaurantList.innerHTML = "";
    }

    

}
if(document.querySelector("#restaurant")){
    document.querySelector("#restaurant").addEventListener('input', filterRestaurant);
}


document.addEventListener('input',moveLabels);

function moveLabels() {
    let labels = document.querySelectorAll('.contact-form__input');
    let labelsArr = Array.prototype.slice.call(labels);

    labelsArr.forEach(label=>{
       if(label.value){
        label.nextElementSibling.classList.add('input-focused');
       }else{
        label.nextElementSibling.classList.remove('input-focused');
       }
       if(label.tagName == "TEXTAREA" && label.value){
        label.nextElementSibling.classList.remove('input-focused');
        label.nextElementSibling.classList.add('textarea-focused');
       }else{
        label.nextElementSibling.classList.remove('textarea-focused');
       }
    });

}


function selectRestaurent(e){
    document.querySelector("#restaurant").value=e.target.innerText.toUpperCase();
    $(".restaurant-list").hide();
}

} 

}


export default new contactForm;