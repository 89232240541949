class newsletter {
    ready () {
        $("#newsletter-form").submit(function(e) {
            e.preventDefault();
            var form = $(this);
            var url = form.attr('action');

            $.ajax({
                type: "POST",
                dataType: 'json',
                url: url,
                data: form.serialize(),
                success: function(data) {
                    if (data.status == 'True' ){
                        $("#result_message_success").text(data.message);
                        $("#result_message_error").text('');
                        $(".lblError").hide();
                    }else{
                        $("#result_message_error").text(data.message);
                        $("#result_message_success").text('');
                        $(".lblError").hide();
                    }
                }
            });
        });

        // Mail validation
        $( ".newsletter-form-control" ).keyup(function() {
            /* eslint-disable */
            var expr =/^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
            if (!expr.test($(this).val())) {
                $(this).siblings('span').each(function(){
                    if($(this).hasClass('lblError')){
                      $(this).show();
                      $("#result_message_error").text('');
                      $("#result_message_success").text('');
                    }
                });
            }else{
                $(this).siblings('span').each(function(){
                    if($(this).hasClass('lblError')){
                      $(this).hide();
                      $("#result_message_error").text('');
                      $("#result_message_success").text('');
                    }
                });
            }
            //remove error if input is empty
            if(!$( ".newsletter-form-control" ).val()){
                if($('.lblError').length){
                    $('.lblError').hide();
                }
            }
        });

    }
}

export default new newsletter;